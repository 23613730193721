#root {
  width: 100dvw;
  margin: 0;
  text-align: center;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: repeat(12, calc(100dvw/12));
  grid-template-rows: 3rem 1fr auto;

  min-height: 100svh;
}

nav#header {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1;
}

nav#header ul {
  display: flex;
  gap: 1em;
  flex-direction: row;
  align-items: center;
}

nav#header ul li {
  list-style-type: none;
}

nav#header {
  border-bottom: 1px solid black
}

a.active {
  color: red !important
}

main, aside {
  grid-row-start: 2;
  grid-row-end: 2;
  padding: 1em;
}

aside {
  overflow-y: scroll;
}

main {
  overflow-y: scroll;
  overflow-y: hidden;
}

.colspan1 {grid-column: span 1}
.colspan2 {grid-column: span 2}
.colspan3 {grid-column: span 3}
.colspan4 {grid-column: span 4}
.colspan5 {grid-column: span 5}
.colspan6 {grid-column: span 6}
.colspan7 {grid-column: span 7}
.colspan8 {grid-column: span 8}
.colspan9 {grid-column: span 9}
.colspan10 {grid-column: span 10}
.colspan11 {grid-column: span 11}
.colspan12 {grid-column: span 12}

footer {
  display:flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 3;
  border-top: 1px solid black;
  padding-inline: 1em;
}

#questionList li a {
  display:block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.winningPoster {
  max-width: 80vw;
  max-height: 80vh;
}

#replay, 
#stats {
  margin: 1rem;
  max-width: 80vw;
  text-align: left;
}

#replay h3,
#stats h3 {
  margin-inline-start: 1em;
  margin-bottom: 0;
}
#replay p,
#stats p {
  margin-top: 0;
  margin-inline-start: 2.5em;
}

.partij-display {
  display: flex;
  margin: 1rem;
  gap: 2rem;
}
.partij-display:nth-child(2n) {
  flex-direction: row;
  text-align: left;
}
.partij-display:nth-child(2n+1) {
  flex-direction: row-reverse;
  text-align: right;
}
.partij-display div {
  max-width: 50vw;
  min-width: 20vw;
}
.partij-display img {
  max-width: 50vw;
  max-height: 50vh;
}

#party-list {
  max-width: 1200px;
  justify-self: center;
}

#kieswijzer, #home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-container {
  margin: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
}

input[type="button"], #home a {
  padding: 1em;
  min-width: 20ch;
  border-radius: 15px;
  border: 2px solid black;
  background-color: white;
  color: black;
  font-size: large;
}

input[type="button"]:hover {
  background-color: #aaa;
  cursor: pointer;
}

#home h1 {
  font-size: 10rem;
  margin-bottom: 0;
}

#header ul {
  margin: 0;
  padding: 0;
  height: 100%;
  padding-left: 1em;
}

#header li {
  font-size: 2em;
  height: min-content;
}

#header a {
  font-size: 1em;
  color: black;
}

@media (width < 600px) {
  #home h1 {
    font-size: 5rem;
  }

  input[type="button"], #home a {
    padding: 0.5em;
    font-size:medium;
    min-width: 10ch;
  }

  #header li {
    font-size: 1.5em;
  }
}
